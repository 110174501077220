<template>
  <div class="stopwatch d-flex align-items-center">
    <template v-if="currentStopwatch && currentStopwatch.id">
      <el-dropdown
          class="d-flex align-self-center"
          placement="bottom-start"
          trigger="click"
          v-if="stopwatches.length"
      >
        <div class="d-flex justify-content-center dropdown-icon">
          <i
              class="el-icon-arrow-down"
              style="margin-top: 8px"
          ></i>
        </div>
        <el-dropdown-menu
            slot="dropdown"
            style="z-index: 1 !important;"
        >
          <div
              :key="stopwatch.id"
              class="mx-3"
              v-for="stopwatch in stopwatches"
          >
            <StopwatchItem
                :canBeRemoved="!stopwatch.timeSpent && !stopwatch.startedAt"
                :id="stopwatch.id"
                :isStopwatchActive="false"
                :issue-id="stopwatch.entityId"
                :time="getStopwatchTime(stopwatch.totalSeconds)"
                @pause="pause"
                @remove="remove"
                @start="start"
                @stop="stop"
            />
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <StopwatchItem
          :id="currentStopwatch.id"
          :isStopwatchActive="currentStopwatch.isStopwatchActive"
          :issue-id="currentStopwatch.entityId"
          :time="getStopwatchTime(currentStopwatch.totalSeconds)"
          @pause="pause"
          @start="start"
          @stop="stop"
          class="mr-2"
      />
      <LogTimeDialog
          :backend_errors="backend_errors"
          :is-visible="isDialogOpen"
          :logged-seconds="stoppedStopwatch && stoppedStopwatch.totalSeconds ? stoppedStopwatch.totalSeconds : 0"
          @close="onDialogClose"
          @save="logTime"
      />
    </template>
    <router-link
        class="d-flex stopwatch--additional-icons"
        to="/time_logger"
        v-if="user.admin"
    >
      <i class="el-icon-data-analysis" />
    </router-link>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import issue from '../../mixin/issue';
import axios from 'axios';
import Vue from 'vue';
import StopwatchItem from './StopwatchItem.vue';
import {diff, HHHmmToHHmmss, secondsToHHmmss} from '../../helpers/time';

export default {
  name: 'Stopwatch',
  mixins: [issue],
  components: {
    LogTimeDialog: () => import('./LogTimeDialog.vue'),
    StopwatchItem,
  },
  data() {
    return {
      backend_errors: {},
      lastUpdate: false,
      currentStopwatch: {
        isStopwatchActive: false,
        totalSeconds: 0,
      },
      timer: null,
      stopwatches: [],
      isDialogOpen: false,
      isFirstLoad: true,
      stoppedStopwatch: null,
    };
  },
  beforeMount() {
    this.fetchStopwatches();
    this.setTimer();
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('stopwatches', [
      'setStopwatches',
      'setCurrentStopwatch',
    ]),
    getStopwatchTime(sec) {
      return secondsToHHmmss(sec);
    },
    resolveResponse(response) {
      // return !!(this.currentStopwatch && this.currentStopwatch.entityId &&
      //   this.currentStopwatch.entityId === item.entityId);
      if (this.lastUpdate !== response.data.lastUpdate) {
        const data = [...response.data.data];
        let currentStopwatchIdx = data.findIndex(item => item.currentState === 'start');
        if (currentStopwatchIdx === -1) {
          currentStopwatchIdx = data.findIndex(item =>
              this.currentStopwatch && this.currentStopwatch.entityId &&
              this.currentStopwatch.entityId === item.entityId);
        }
        if (currentStopwatchIdx !== -1) {
          this.currentStopwatch = {
            ...data[currentStopwatchIdx],
            isStopwatchActive: data[currentStopwatchIdx].currentState === 'start',
            totalSeconds: this.currentStopwatch.totalSeconds,
          };
          data.splice(currentStopwatchIdx, 1);
        } else if (data.length) {
          this.currentStopwatch = {
            isStopwatchActive: false,
            ...data[0],
            totalSeconds: this.currentStopwatch.totalSeconds,
          };
          data.splice(0, 1);
        } else {
          this.currentStopwatch = {};
        }
        this.setCurrentStopwatch(this.currentStopwatch);
        this.stopwatches = data.map(item => {
          return {
            ...item,
            isStopwatchActive: false,
            totalSeconds: item.timeSpent,
          };
        });
        this.setStopwatches(this.stopwatches);
        this.lastUpdate = response.data.lastUpdate;
      }
    },
    fetchStopwatches() {
      const params = {};
      if (this.lastUpdate) {
        params.last_update = this.lastUpdate;
      }
      // TODO uncomment
      axios.get('time-logger/list', {params})
          .then((response) => {
            this.resolveResponse(response);
            this.fetchStopwatches();
          });
    },
    setTimer() {
      this.removeTimer();
      this.timer = setInterval(() => {
        const spentTime = this.currentStopwatch.timeSpent ? this.currentStopwatch.timeSpent : 0;
        Vue.set(this.$data.currentStopwatch, 'totalSeconds', spentTime + diff(this.currentStopwatch.startedAt));
      }, 1000);
    },
    removeTimer() {
      clearInterval(this.timer);
    },
    async start(issueId) {
      await axios.post('time-logger/start', {
        entityId: issueId,
        entityFormId: 0,
      }).then((response) => {
        this.resolveResponse(response);
      });
    },
    pause(id) {
      return axios.patch(`time-logger/${id}/pause`).then((response) => {
        this.resolveResponse(response);
      });
    },
    async stop(id) {
      let stopwatch = null;
      if (this.currentStopwatch.id === id) {
        stopwatch = this.currentStopwatch;
      } else {
        stopwatch = this.stopwatches.find(item => item.id === id);
      }
      if (stopwatch) {
        if (stopwatch.currentState === 'start') {
          await this.pause(id);
        }
        this.stoppedStopwatch = {...stopwatch};
        this.openDialog();
      }
    },
    remove(id) {
      return axios.delete(`time-logger/${id}/delete`).then(response => {
        this.resolveResponse(response);
      });
    },
    openDialog() {
      this.backend_errors = {};
      this.isFirstLoad = false;
      this.isDialogOpen = true;
    },
    logTime(data) {
      const formClone = {...data};
      formClone.timeSpent = HHHmmToHHmmss(formClone.timeSpent);
      return axios.post(`time-logger/${this.stoppedStopwatch.id}/stop`, formClone)
          .then(response => {
            this.resolveResponse(response);
            this.isDialogOpen = false;
            this.openIssue(this.stoppedStopwatch.entityId);
            this.stoppedStopwatch = null;
          })
          .catch(error => {
            console.log('error!!!');
            this.backend_errors = error.response.data.errors;
          });
    },
    onDialogClose() {
      this.isDialogOpen = false;
      this.stoppedStopwatch = null;
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.stopwatch {
  color: #ffffff;

  .stopwatch--additional-icons {
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, .2);
    }
  }

  .dropdown-icon {
    color: white;
    width: 28px;
    height: 28px;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background: rgba(255, 255, 255, .2);
    }
  }
}
</style>
