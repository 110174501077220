import {mapActions} from 'vuex';

export default {
  methods: {
    ...mapActions('modal', ['addIssuesIds']),
    async openIssue(issueId, issuesIdsList) {
      await this.$router.push(this.getIssueLink(issueId)).catch(() => {
      });
      if (issuesIdsList) {
        await this.addIssuesIds(issuesIdsList);
      }
    },
    getIssueLink(issueId) {
      return {path: this.$route.path, query: {...this.$route.query, issueId}};
    },
  },
};
