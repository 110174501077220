<template>
  <div class="d-flex align-self-center justify-content-end stopwatch-item">
    <router-link
        :to="getIssueLink(issueId)"
        class="entity-identifier"
    >
      <b>#{{ issueId }}</b>
    </router-link>
    <span class="mr-2 ml-1">
      {{ time }}
    </span>
    <div class="control-buttons">
      <font-awesome-icon
          :class="isStopwatchActive ? 'disabled' : ''"
          @click="$emit('start', issueId)"
          class="control-button"
          icon="play"
      />
      <font-awesome-icon
          :class="!isStopwatchActive ? 'disabled' : ''"
          @click="$emit('pause', id)"
          class="control-button"
          icon="pause"
      />
      <font-awesome-icon
          @click="$emit('remove', id)"
          class="control-button"
          icon="trash"
          v-if="canBeRemoved"
      />
      <font-awesome-icon
          @click="$emit('stop', id, issueId, time)"
          class="control-button"
          icon="stop"
          v-else
      />
    </div>
  </div>
</template>

<script>
import issue from '../../mixin/issue';

export default {
  name: 'StopwatchItem',
  mixins: [issue],
  props: {
    id: {
      required: true,
    },
    issueId: {
      required: true,
    },
    isStopwatchActive: {
      required: true,
    },
    time: {
      required: true,
    },
    canBeRemoved: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.stopwatch-item {
  .entity-identifier,
  .entity-identifier:focus {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .control-buttons {
    line-height: 1.5;

    .control-button {
      height: 0.9em;
      margin-right: 4px;
    }

    .disabled {
      opacity: 0.6 !important;
      cursor: not-allowed !important;
    }
  }
}
</style>
